/* eslint-disable react/no-danger */
import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Page from '../components/layouts/Page'

const Static = ({
  data: {
    page: {
      html,
      frontmatter: { title },
    },
  },
}) => (
  <Page title={title} description="" leftColumn="">
    <div
      className="staticPages"
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  </Page>
)

Static.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      html: PropTypes.string.isRequired,
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export default Static

export const query = graphql`
  query StaticPageQuery($fileAbsolutePath: String!) {
    page: markdownRemark(fileAbsolutePath: { eq: $fileAbsolutePath }) {
      frontmatter {
        title
      }
      html
    }
  }
`
